import React from "react";

export function LegalContactText() {
  return (
    <p>
      Lithic Technology
      <br />
      3439 SE Hawthorne Blvd #444
      <br />
      Portland, OR, 97214
      <br />
      Email Address: hello@webhookdb.com
      <br />
    </p>
  );
}
